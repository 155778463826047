<template>
    <section>
        <datos-basicos :crear="crear" :tipo="tipo" :clientes="clientes" />
        <div v-show="id_cupon">
            <div class="row mx-0">
                <div class="col-8 bg-white br-b-8">
                    <div class="row mx-0">
                        <div class="col-5">
                            <p class="pl-3 text-general f-15 f-500">
                                País (Obligatorio)
                            </p>
                            <el-select v-model="valuePais" class="w-100" size="small" @change="getCedis">
                                <el-option
                                v-for="(p,index) in paises"
                                :key="`pais${index}`"
                                :label="p.pais"
                                :value="p.id"
                                />
                            </el-select>
                        </div>
                        <div class="col">
                            <div class="row mx-0">
                                <div class="col px-0">
                                    <p class="pl-3 text-general f-15 f-500">
                                        Añadir cedis
                                    </p>
                                    <el-select v-model="valueCedis" class="w-100" size="small" @change="agregarCedis">
                                        <el-option
                                        v-for="(c,index) in cedis"
                                        :key="`cedi${index}`"
                                        :label="c.nombre"
                                        :value="c.id"
                                        :disabled="c.disabled"
                                        />
                                    </el-select>
                                </div>
                                <!-- <div class="col-auto px-3" /> -->
                            </div>
                            <div v-for="(cc,index) in cedisCupon" :key="`cc${index}`" class="row mx-0 my-3">
                                <div class="col px-0 bg-whitesmoke2 px-2 py-1 br-4 border f-14 w-100 d-middle" style="height:32px">
                                    {{ cc.nombre }}
                                    <el-tooltip placement="bottom" content="Eliminar" effect="light">
                                        <i class="icon-cancel f-20 hover-inverse cr-pointer ml-auto" @click="removerCedis(cc)" />
                                    </el-tooltip>
                                </div>
                                <!-- <div class="col-auto px-0 d-middle">
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div v-if="datosBasicos.tipo == 1" class="row mx-0 pt-3">
                        <div class="col-3 text-general text-center f-17">
                            Tipo descuento
                        </div>
                        <div class="col text-center">
                            <div class="d-middle-center my-1">
                                <input v-model="datosBasicos.descuento_tipo" size="medium" :value="1" type="radio" class="option-input radio" @change="cambioTipoDescuento(1)" /> Descuento %
                            </div>
                            <el-input v-show="datosBasicos.descuento_tipo == 1"
                                      v-model="datosBasicos.descuento"
                                      size="medium" class="w-100"
                                      @input="cambioDescuento($event,'input')"
                                      @change="cambioDescuento($event,'change')"
                            >
                                <template slot="append">
                                    %
                                </template>
                            </el-input>
                            <p v-show="datosBasicos.descuento_tipo == 1 && errorDescuento" class="f-11 text-danger">El campo debe ser numerico</p>
                        </div>
                        <div class="col text-center">
                            <div class="d-middle-center my-1">
                                <input v-model="datosBasicos.descuento_tipo" size="medium" :value="2" type="radio" class="option-input radio" @change="cambioTipoDescuento(2)" /> Descuento valor
                            </div>
                            <el-input v-show="datosBasicos.descuento_tipo == 2"
                                      v-model="datosBasicos.descuento" size="medium" class="w-100"
                                      @input="cambioDescuento($event,'input')"
                                      @change="cambioDescuento($event,'change')"
                            >
                                <template slot="prepend">
                                    $
                                </template>
                            </el-input>
                            <p v-show="datosBasicos.descuento_tipo == 2 && errorDescuento" class="f-11 text-danger">El campo debe ser numerico</p>
                        </div>
                    </div>
                    <div class="row mx-0 my-4">
                        <div class="col-3 text-general d-middle-center text-center f-17">
                            Limítes de uso
                        </div>
                        <div class="col">
                            <p class="text-general text-center">
                                Límite de uso por cupón
                            </p>
                            <el-input v-model="datosBasicos.limite_total" size="medium" :disabled="datosBasicos.limite_total == null" class="w-100" @change="cambiarLimite($event,'total')" />
                            <p v-show="datosBasicos.descuento_tipo == 1 && errorDescuento" class="f-11 text-danger">El campo debe ser numerico</p>
                            <el-checkbox :value="checkSinLimiteTotal" class="check-red mx-5" @change="cambiarSinLimite($event,'total')">
                                Sin Límite
                            </el-checkbox>
                        </div>
                        <div class="col">
                            <p class="text-general text-center">
                                Límite de uso por cliente
                            </p>
                            <el-input v-model="datosBasicos.limite_usuario" size="medium" :disabled="datosBasicos.limite_usuario == null" class="w-100" @change="cambiarLimite($event,'usuarios')" />
                            <p v-show="datosBasicos.descuento_tipo == 1 && errorDescuento" class="f-11 text-danger">El campo debe ser numerico</p>
                            <el-checkbox :value="checkSinLimiteUsuarios" class="check-red mx-5" @change="cambiarSinLimite($event,'usuarios')">
                                Sin Límite
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <condiciones-clientes />
            <div class="row mx-0 mt-3 mb-3">
                <div class="col-8 py-3 px-3 bg-white br-12">
                    <p class="text-general f-600 f-17 mb-3">
                        Limítes del uso del cupón
                    </p>
                    <div class="row mx-0 my-3">
                        <div class="col-4 text-general d-middle">
                            Valor mínimo de compra
                        </div>
                        <div class="col-3 pr-4">
                            <el-input-number v-model="datosBasicos.compra_minima" size="small" class="w-100" :controls="false" :min="0" @change="cambioLimitesUso($event,'compra_minima')" />
                        </div>
                    </div>
                    <div v-show="datosBasicos.descuento_tipo === 1" class="row mx-0 my-3">
                        <div class="col-4 text-general d-middle">
                            Descuento máximo aplicado
                        </div>
                        <div class="col-3 d-middle pr-0">
                            <el-input-number v-model="datosBasicos.descuento_maximo" size="small" class="w-100" :controls="false" :min="0" @change="cambioLimitesUso($event,'descuento_maximo')" />
                            <el-tooltip placement="bottom" content="Cuando se supere este valor, el descuento se aplicará a este máximo." effect="light">
                                <i class="icon-info-circled-alt text-general2 f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="row mx-0 mt-4">
                        <div class="col-4 text-general">
                            Vigencia
                        </div>
                        <div class="col-7">
                            <div class="row mx-0">
                                <div class="col-auto d-middle px-0">
                                    Inicio
                                </div>
                                <div class="col-6">
                                    <el-date-picker
                                    v-model="datosBasicos.fecha_inicio"
                                    type="datetime"
                                    size="small"
                                    class="w-100"
                                    placeholder="-"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    :clearable="false"
                                    @change="cambiarFecha($event,'fecha_inicio')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 my-3">
                        <div class="col-4" />
                        <div class="col-7">
                            <div class="row mx-0">
                                <div class="col-auto d-middle pr-3 px-0">
                                    Fin
                                </div>
                                <div class="col-6">
                                    <el-date-picker
                                    v-model="datosBasicos.fecha_fin"
                                    size="small"
                                    type="datetime"
                                    class="w-100"
                                    placeholder="-"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    :clearable="false"
                                    @change="cambiarFecha($event,'fecha_fin')"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <seccion-lecheros v-if="datosBasicos.destinatario == 1" :id-cupon="id_cupon" :datos-basicos="datosBasicos" />
            <div class="row mx-0 mt-3">
                <div class="col-8 py-3 px-3 bg-white br-t-8">
                    <p class="text-general f-600 f-17 mb-3">
                        Productos aplicados
                    </p>
                    <div class="row mx-0">
                        <div class="col-12">
                            <el-checkbox v-model="datosBasicos.productos_oferta" :true-label="1" :false-label="0" class="check-red" @change="cambioLimitesUso($event,'productos_oferta')">
                                Incluir productos en oferta
                            </el-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <todo-catalogo :id-cupon="id_cupon" :datos-basicos="datosBasicos" />
        </div>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
import moment from 'moment'
export default {
    components: {
        datosBasicos:() => import('./datosBasicos'),
        condicionesClientes:() => import('./condicionesClientes'),
        todoCatalogo:() => import('./todoCatalogo'),
        seccionLecheros:() => import('./seccionLecheros'),
    },
    props:{
        crear:{
            type: Boolean,
            default: false
        },
        tipo:{
            //1=>inmediato 2 =>futuro Padre 3=> futuro hijo
            type:Number,
            default:1
        },
        clientes:{
            type:Number,
            default:1
        }
    },
    data(){
        return {
            paises:[],
            valuePais:null,
            cedis:[],
            valueCedis:null,
            errorDescuento:false,
            checked:true,

        }
    },
    computed:{
        ...mapGetters({
            cedisCupon: 'cupones/cupones/cedisCupon',
            datosBasicos: 'cupones/cupones/datosBasicos',
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        },
        checkSinLimiteTotal(){
            return Boolean(this.datosBasicos.limite_total == null)
        },
        checkSinLimiteUsuarios(){
            return Boolean(this.datosBasicos.limite_usuario == null)
        }
    },
    mounted(){
        this.listarPaises()
        this.getCuponesCedis()
        console.log(this.datosBasicos);
    },
    methods:{
        async listarPaises(){
            try {

                const {data} = await Cupones.listarPaises()
                this.paises = data

            } catch (e){
                this.error_catch(e)
            }
        },
        async getCedis(idPais){
            try {
                const {data} = await Cupones.listarCedis(idPais)
                data.cedis.map(c=>{
                    let find = this.cedisCupon.find(o=>o.id_cedis === c.id)
                    if(find){
                        c.disabled = true
                    }
                })
                this.cedis = data.cedis
            } catch (e){
                this.error_catch(e)
            }
        },
        getCuponesCedis(){
            this.$store.dispatch('cupones/cupones/getCuponesCedis',this.id_cupon)
        },
        async agregarCedis(idCedis){
            try {
                let form = {
                    id_cedis: idCedis,
                    id_cupon: this.id_cupon
                }
                const {data} = await Cupones.agregarCedis(form)
                this.valueCedis = null
                this.getCuponesCedis()
                let index = this.cedis.findIndex(o=>o.id === idCedis)
                this.cedis[index].disabled = true

            } catch (e){
                this.error_catch(e)
            }
        },
        async removerCedis(item){
            try {
                const {data} = await Cupones.removerCedis(item.id)
                this.getCuponesCedis()

                let index = this.cedis.findIndex(o=>o.id === item.id_cedis)
                if(index > -1){
                    this.cedis[index].disabled = false
                }

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioTipoDescuento(value){
            try {
                const {data} = await Cupones.cambioTipoDescuento(this.id_cupon,value)
                this.errorDescuento = false
                this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)

            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioDescuento(value,tipo){
            if(tipo === 'input'){
                if(isNaN(value)){
                    this.errorDescuento = true
                }else{
                    this.errorDescuento = false
                }
                return
            }
            if(tipo === 'change' && !isNaN(value)){
                let form = {
                    id_cupon:this.id_cupon,
                    descuento:value
                }
                const {data} = await Cupones.cambioDescuento(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)
            }
        },
        async cambiarSinLimite(val,tipo){
            //console.log(val,tipo);
            let form = {
                tipo: tipo,
                value: val
            }
            const {data} = await Cupones.cambiarSinLimite(this.id_cupon,form)
            this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)
        },
        async cambiarLimite(value,tipo){
            try {

                let form = {
                    tipo,
                    value:isNaN(value) ? 1 : value
                }
                const {data} = await Cupones.cambiarLimite(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)


            } catch (e){
                this.error_catch(e)
            }
        },
        async cambioLimitesUso(value,campo){
            try {

                let form = {
                    columna:campo,
                    value
                }

                const {data} = await Cupones.cambiarCondiciones(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)
            } catch (e){
                this.error_catch(e)
            }
        },
        async cambiarFecha(value,campo){
            try {

                if (campo == 'fecha_fin' && moment(this.datosBasicos.fecha_inicio).isAfter(moment(value))){
                    return this.notificacion('', 'La fecha fin debe ser mayor a la fecha de inicio', 'error')
                }

                if (campo == 'fecha_inicio' && moment(this.datosBasicos.fecha_fin).isBefore(moment(value))){
                    return this.notificacion('', 'La fecha fin debe ser mayor a la fecha de inicio', 'error')
                }

                let form = {
                    columna:campo,
                    value
                }

                const {data} = await Cupones.cambiarFecha(this.id_cupon,form)
                this.$store.commit('cupones/cupones/set_datos_basicos',data.cupon)
            } catch (e){
                this.error_catch(e)
            }
        }

    }

}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}
.inactive-button{
    background-color: #F5F5F5;
    color: #707070;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
